import { useState } from "react";
import { TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import logoTP from "../../../Asset/logo.svg";
import authService from "../../../Services/AuthService";
import ModalNoti from "../ModalNoti/ModalNoti";
import { useEffect } from "react";
import sharedService from "../../../Services/SharedService";
import { goTo } from "../../../Utils/helper";
import { ID_GROUPS, IMG_GROUP } from "../../../Utils/const";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from "../AlertNoti/AlertNoti";

const Login = () => {
	const [account, setAccount] = useState({});
	const [noti, setNoti] = useState({
		open: false,
		message: "",
		severity: ""
	});
	const [listUsers, setListUsers] = useState([]);
	const [imageGroup, setImageGroup] = useState("default");
	const [group, setGroup] = useState();
	const [loading, setLoading] = useState(false)

	const checkStorage = sessionStorage.getItem('check')
	const checkAC = sessionStorage.getItem('check_login_ac')
	const subdomain = window.location.hostname;
	const group_id = window.localStorage.getItem("groupId");
	const prevGroupId =
		window.localStorage.getItem("group_id");
	if (group_id && prevGroupId && group_id !== prevGroupId) {
		window.localStorage.removeItem("group_id");
		window.localStorage.removeItem("token");
	}
	useEffect(() => {
		setLoading(true)
		sharedService
			.getListUser()
			.then((res) => {
				if (res?.data.length !== 0) {
					setListUsers(res?.data);
				}
			})
			.catch((err) => console.log(err));
		sharedService
			.listBoxGroup()
			.then((res) => {
				const listGroup = res?.data.find(g => g.subdomain === subdomain)
				if (listGroup) {
					const group = IMG_GROUP.find(
						(g) => g.subdomain === listGroup.subdomain
					);
					setLoading(false)
					setGroup(listGroup)
					setImageGroup(group.img);
				}
				else {
					setLoading(false)
				}
			})
			.catch((err) =>
				console.log(err, "err call api list box group")
			);
	}, [subdomain]);
	const onChangeAccount = (e) => {
		const { name, value } = e.target;
		setAccount({ ...account, [name]: value });
	};

	const Login = () => {
		authService
			.login(account)
			.then((res) => {
				const user = listUsers.find(
					(u) => u.id === res?.data.user_id
				);
				// if(user.groups.includes('KKKXW2TW')){
				// 	setNoti({
				// 		open: true,
				// 		message: "Tài khoản của bạn đã bị vô hiệu hoá, vui lòng liên hệ với nhân sự chuyên trách thuộc Thuận Phong để được hỗ trợ!",
				// 		severity: "error"
				// 	});
				// }
				// else {
				if (group === undefined) {
					const token = res.data;
					if (token) {
						authService.setGroupId(user.groups[0]);
						authService.setToken(token);
						authService.setUserInfo(user)
						if (checkStorage !== null) {
							sessionStorage.setItem('check', true)
							goTo("app/help");
						} else if (checkAC !== null && user.groups.includes(ID_GROUPS.AC)) {
							sessionStorage.setItem('check_login_ac', true)
							goTo("app/help/audio");
						}
						else {
							goTo("app/dashboard");
						}
					}
				} else {
					if (user.groups.includes(group.id)) {
						const token = res.data;
						if (token) {
							authService.setGroupId(group.id);
							authService.setToken(token);
							authService.setUserInfo(user)
							if (checkStorage !== null) {
								sessionStorage.setItem('check', true)
								goTo("app/help");
							} else if (checkAC !== null && group.id === ID_GROUPS.AC) {
								sessionStorage.setItem('check_login_ac', true)
								goTo("app/help/audio");
							}
							else {
								goTo("app/dashboard");
							}
						}
					} else {
						setNoti({
							open: true,
							message: "Tài khoản không có quyền truy cập !",
							severity: "warning"
						});
					}
				}
				// }
			})
			.catch((err) => {
				console.log('err', err)
				setNoti({
					open: true,
					message: "Tên đăng nhập hoặc mật khẩu chưa đúng !!!",
					severity: "error"
				})
			});
	};

	const done = () => {
		setNoti({
			open: false,
			message: "",
			severity: ""
		})
	};

	return (
		<>
			<CustomizedSnackbars open={noti.open} message={noti.message} severity={noti.severity} handleClose={done} />
			<div className="login">
				{loading ?
					<div className="image-left">
						<Stack sx={{ color: 'grey.500', width: '100%', textAlign: 'center' }} spacing={2}>
							<CircularProgress color="info" size="15rem" />
							<CircularProgress color="success" size="10rem" />
							<CircularProgress color="inherit" size="5rem" />
						</Stack>
					</div>
					: <div className={`image-left ${imageGroup}`}> </div>
				}
				<div className="el-right">
					<div className="box-login">
						<div className="el-title">
							<div className="image-group">
								<img
									src={logoTP}
									alt="Logo Thuan Phong"
									width="125px"
									height="72px"
								/>
							</div>
							<div>
								<p className="title">
									HỆ THỐNG GIÁM SÁT TỰ ĐỘNG CÁC CÔNG TRÌNH
									THUỶ LỢI
								</p>
							</div>
						</div>
						<div className="mt-60">
							<Grid className="text-label">
								Tên đăng nhập:
							</Grid>
							<Grid sx={{ pt: 2, pb: 2 }}>
								<TextField
									fullWidth
									label="Tên đăng nhập"
									name="username"
									onChange={onChangeAccount}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											Login();
										}
									}}
								/>
							</Grid>
							<Grid className="text-label">Mật khẩu:</Grid>
							<Grid sx={{ pt: 2, pb: 2 }}>
								<TextField
									fullWidth
									type="password"
									label="Mật khẩu"
									name="password"
									onChange={onChangeAccount}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											Login();
										}
									}}
								/>
							</Grid>
							<div className="option">
								<div className="width-percent-100 end forget-password">
									<a href="/forget">Quên mật khẩu</a>
								</div>
							</div>
							<Grid className="text-center mt-20">
								<Button
									className="button-login"
									variant="contained"
									onClick={Login}
								>
									đăng nhập
								</Button>
							</Grid>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
