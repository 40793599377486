export const RECTS_DEFAULT = [
    {
        box_id: "TC4SPI56",
        image: "daton_mucnuocho.png",
        value: {
            startX: 185,
            endX: 685,
            y: 500,
            width: 3,
            default: 122,
            title1: "MNHT: ",
            title2: "Dung tích hồ: ",
            y2: 230
        },
        ratio: 17.5
    },
    {
        box_id: "JVPRAJG1",
        image: "songmay_mucnuocho.png",
        value: {
            startX: 142,
            endX: 880,
            y: 602,
            width: 3,
            default: 14,
            title1: "MNHT: ",
            title2: "Dung tích hồ: ",
            y2: 300
        },
        ratio: 17
    },
    {
        box_id: "WK34ZRT9",
        image: "songluy_mucnuoc3.png",
        value: {
            startX: 135,
            endX: 624,
            y: 501,
            width: 3,
            default: 116,
            title1: "ZTL(m): ",
            title2: "V(triệu m3): ",
            y2: 660,
            left: 73,
            bottom: 530,
            right: 421
        },
        ratio: 19,
        v_design: 99.9,
        fill: [77, 95, 105, 110, 115, 120, 125, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 225, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246]
    },
    {
        box_id: "XGG4IBUN",
        image: "hoduongdong_mucnuoc.png",
        value: {
            startX: 135,
            endX: 624,
            y: 511,
            width: 3,
            default: 14,
            title1: "ZTL(m): ",
            title2: "V(triệu m3): ",
            y2: 660,
            left: 73,
            bottom: 542,
            right: 431
        },
        ratio: 19.5,
        v_design: 6.429,
        fill: [80, 95, 105, 115, 120, 130, 140, 150, 158, 167, 175, 180, 190, 200, 205, 210, 215, 220, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252]
    },
    {
        box_id: 'Z1AKDBAT',
        image: "hocaymen_mucnuoc.png",
        value: {
            startX: 135,
            endX: 624,
            y: 555.5,
            width: 3,
            default: 8,
            title1: "ZTL(m): ",
            title2: "V(nghìn m3): ",
            y2: 780,
            left: 75,
            bottom: 601,
            right: 478
        },
        ratio: 21.75,
        v_design: 190.76,
        fill: [80, 95, 105, 115, 120, 130, 140, 150, 158, 167, 175, 180, 190, 200, 205, 210, 215, 225, 235, 245, 255, 256, 257,258,259,260,261,262,263,264,265,266,267,268,269,270,271,272,273,274,275,276,277,278,279,280]
    },
    {
        box_id: "LPDNWOUM",
        image: "ac.jpg",
        value: {
            // startX: 335,
            // endX: 624,
            y: 685,
            //y: 615,
            width: 3,
            default: 23.6,
            //default: 27,
            title1: "ZTL(m): ",
            title2: "V(triệu m3): ",
            y2: 750,
            left: 153,
            bottom: 685,
            right: 420,
            //bottom: 615
        },
        ratio: 20.5,
        v_design: 4.29,
    },
]