import React, { Children } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import SpeedIcon from '@mui/icons-material/Speed';
import Typography from '@mui/material/Typography';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import PersonIcon from '@mui/icons-material/Person';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RefreshIcon from '@mui/icons-material/Refresh';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import BarChartIcon from '@mui/icons-material/BarChart';

import MenuApp from '../MenuApp/MenuApp';
import DashBoard from '../../Pages/DashBoard/Dashboard';
import Monitor from '../../Pages/Monitor/Monitor';
import Zone from '../../Pages/Zone/Zone';
import Group from '../../Pages/Group/Group';
import Login from "../../Components/Login/Login";
import Help from "../../Pages/Help/Help"

import sharedService from "../../../Services/SharedService";
import { CUSTOM_SIDEBAR, DOMAIN } from './Utils/CustomSideBar';
import { CHECK_EMPTY_STRING } from '../../../Utils/string';
import { grey } from '@mui/material/colors';
import { format } from "date-fns";

import logo from "../../../Asset/logo.svg"
import authService from '../../../Services/AuthService';
import { goTo } from '../../../Utils/helper';
import User from '../../Pages/User/User';
import ChartComponent from '../../Pages/Chart/Chart';
import { Alert, Button } from '@mui/material';
import { BOXGROUP_ID, ID_GROUPS } from '../../../Utils/const';
import { NewExcel } from '../../../Utils/excel';
import { Menu, drawerWidth } from './Utils/CustomSideBar';
import { BOX_DOT2 } from './data';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Audio from '../../Pages/Help/Components/AC/Audio';

class App extends React.Component {
    constructor(props) {
        super(props)
        this.container = this.props.window !== undefined ? () => window().document.body : undefined;
        this.domain = window.location.pathname;
        this.now = format(Date.now(), "yyyy-MM-dd");

        this.state = {
            open: false,
            groups: [],
            group_id: "",
            time: {
                start: this.now,
                end: this.now
            },
            auth: false,
            mobileOpen: false,
            boxSelected: "",
            metricSelected: "",
            exportExcel: false,
            searchRecords: false,
            userName: authService?.getUserInfo()?.full_name || ""
        }
    }

    drawer(domain) {
        return (
            <div>
                <div className='p-14'>
                    <Grid container>
                        <Grid xs={3} item>
                            <img src={logo} alt="logo" />
                        </Grid>
                        <Grid xs={9} item className="pt-6 pl-10">
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: 14
                                }}
                            >THUẬN PHONG</Typography>
                        </Grid>
                    </Grid>
                </div>
                <List sx={{ width: "100%", maxWidth: drawerWidth }} component="nav" className="left-menu">
                    {Children.toArray(Menu().map((text, index) => (
                        <>
                            <Link
                                href={(() => CUSTOM_SIDEBAR(index))()}
                                underline="none"
                                color={(() => {
                                    if (index === +0) {
                                        if ([CUSTOM_SIDEBAR(index), "/", "/app", "/app/"].includes(domain)) {
                                            return "#FBBC05";
                                        }
                                        return grey[50];
                                    } else {
                                        if (domain === CUSTOM_SIDEBAR(index)) {
                                            return "#FBBC05";
                                        }
                                        return grey[50];
                                    }
                                })()}
                                variant={(() => {
                                    if (index === +0) {
                                        if ([CUSTOM_SIDEBAR(index), "/", "/app", "/app/"].includes(domain)) {
                                            return "h6";
                                        }
                                        return "subtitle1";
                                    } else {
                                        if (domain === CUSTOM_SIDEBAR(index)) {
                                            return "h6";
                                        }
                                        return "subtitle1";
                                    }
                                })()}

                                className={index !== +2 ? "sidebar" : ""}
                            >
                                <ListItemButton
                                    // hidden={index === +2 ? true : false}
                                    key={text}
                                    className="pb-15"
                                    onClick={() => index === +2 && this.handleSubMenu()}
                                >
                                    <ListItemIcon>
                                        {(() => {
                                            switch (index) {
                                                case +0:
                                                    return (
                                                        <SpeedIcon
                                                            className="fontsz-30"
                                                            style={{ color: [CUSTOM_SIDEBAR(index), "/", "/app", "/app/"].includes(domain) ? "#FBBC05" : grey[50] }}
                                                        />
                                                    )
                                                case +1:
                                                    return (
                                                        <>
                                                            {window.localStorage.getItem('group_id') === ID_GROUPS.DOT ?
                                                                <RestoreOutlinedIcon
                                                                    className="fontsz-30 mr-25"
                                                                    style={{ color: domain === '/app/monitor' ? "#FBBC05" : grey[50] }}
                                                                />
                                                                :
                                                                <MonitorHeartOutlinedIcon
                                                                    className="fontsz-30 mr-25"
                                                                    style={{ color: domain === CUSTOM_SIDEBAR(index) ? "#FBBC05" : grey[50] }}
                                                                />
                                                            }

                                                        </>
                                                    )
                                                case +2:
                                                    return (
                                                        <>
                                                            {[ID_GROUPS.KG, ID_GROUPS.AC].includes(window.localStorage.getItem('group_id')) ?
                                                                <RestoreOutlinedIcon
                                                                    className="fontsz-30 mr-25"
                                                                    style={{ color: domain === '/app/monitor' ? "#FBBC05" : grey[50] }}
                                                                />
                                                                :
                                                                window.localStorage.getItem('group_id') === ID_GROUPS.DOT ?
                                                                    <BarChartIcon
                                                                        className="fontsz-30"
                                                                        style={{ color: domain === CUSTOM_SIDEBAR(index) ? "#FBBC05" : grey[50] }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        <RestoreOutlinedIcon
                                                                            className="fontsz-30 mr-25"
                                                                            style={{ color: [`${DOMAIN.MONITOR}/list`, `${DOMAIN.MONITOR}/report`].includes(domain) ? "#FBBC05" : grey[50] }}
                                                                        />
                                                                        <ListItemText
                                                                            primary={text}
                                                                            style={{ color: [`${DOMAIN.MONITOR}/list`, `${DOMAIN.MONITOR}/report`].includes(domain) ? "#FBBC05" : grey[50] }}
                                                                        />
                                                                        {!this.state.open ? <ExpandMore style={{ color: grey[50] }} className="fontsz-20 ml-40 mt-4" /> : <ExpandLess style={{ color: grey[50] }} className="fontsz-20 ml-40 mt-4" />}
                                                                    </>
                                                            }

                                                        </>
                                                    )
                                                case +3:
                                                    return <>
                                                        {window.localStorage.getItem('group_id') === ID_GROUPS.KG ?
                                                            <BarChartIcon
                                                                className="fontsz-30"
                                                                style={{ color: domain === CUSTOM_SIDEBAR(index) ? "#FBBC05" : grey[50] }}
                                                            />
                                                            :
                                                            window.localStorage.getItem('group_id') === ID_GROUPS.DOT ?
                                                                <MonitorHeartOutlinedIcon
                                                                    className="fontsz-30 mr-25"
                                                                    style={{ color: domain === CUSTOM_SIDEBAR(index) ? "#FBBC05" : grey[50] }}
                                                                />
                                                                :
                                                                <PersonIcon
                                                                    className="fontsz-30"
                                                                    style={{ color: domain === CUSTOM_SIDEBAR(index) ? "#FBBC05" : grey[50] }}
                                                                />
                                                        }
                                                    </>
                                                case +4:
                                                    return <>
                                                        {[ID_GROUPS.KG, ID_GROUPS.DOT].includes(window.localStorage.getItem('group_id')) &&
                                                            <PersonIcon
                                                                className="fontsz-30"
                                                                style={{ color: domain === CUSTOM_SIDEBAR(index) ? "#FBBC05" : grey[50] }}
                                                            />
                                                        }
                                                        {[ID_GROUPS.AC].includes(window.localStorage.getItem('group_id')) &&
                                                            <VolumeUpIcon
                                                                className="fontsz-30"
                                                                style={{ color: domain === CUSTOM_SIDEBAR(index) ? "#FBBC05" : grey[50] }}
                                                            />
                                                        }
                                                    </>
                                                default:
                                                    break;
                                            }
                                        })()}
                                    </ListItemIcon>
                                    {![+2].includes(index) && text}
                                    {[ID_GROUPS.DOT, ID_GROUPS.KG, ID_GROUPS.AC].includes(window.localStorage.getItem('group_id')) && index === 2 && text}
                                </ListItemButton>
                            </Link>
                            {ID_GROUPS.AC !== window.localStorage.getItem('group_id') && ID_GROUPS.DOT !== window.localStorage.getItem('group_id') && ID_GROUPS.KG !== window.localStorage.getItem('group_id') &&
                                <Collapse in={index === +2 && this.state.open} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {Children.toArray(["Số liệu thực tế", "Báo cáo tháng",
                                            // "Tính toán nội suy"
                                        ].map((text, index) => {
                                            return (
                                                <Link
                                                    href={(() => {
                                                        switch (index) {
                                                            case +0:
                                                                return `${DOMAIN.MONITOR}/list`;
                                                            case +1:
                                                                return `${DOMAIN.MONITOR}/report`;
                                                            // case +2:
                                                            //     return `${DOMAIN.MONITOR}/compute`;
                                                            default:
                                                                return;
                                                        }
                                                    })()}
                                                    underline="none"
                                                >
                                                    <ListItemButton
                                                        sx={{ pl: 4 }}
                                                    >
                                                        <ListItemIcon sx={{ pl: 3 }}>
                                                            <FiberManualRecordIcon className="fontsz-8" style={{ color: grey[50] }} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={text}
                                                            style={{ color: grey[50] }}
                                                        />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        }))}
                                    </List>
                                </Collapse>
                            }
                        </>
                    )))}
                </List>
            </div >
        )
    };
    handleSubMenu = () => {
        this.setState({ open: !this.state.open });
    }

    handleChangeBoxGroup = (group_id, time) => {
        this.setState({ group_id: group_id });
        if (typeof time === "object") {
            this.setState({
                time: time
            })
        }
    }

    handleChangeBoxSelected = (box_id) => {
        this.setState({ boxSelected: box_id })
    }

    handleChangeMetricSelected= (metric) => {
        this.setState({ metricSelected: metric })
    }

    handleExportExcel = (toggle) => {
        this.setState({ exportExcel: toggle })
    }

    handleSearchRecords = (toggle) => {
        this.setState({ searchRecords: toggle })
    }

    componentDidMount() {
        const token = authService.getToken();
        if (!CHECK_EMPTY_STRING(token)) {
            this.setState({ auth: true });
            sharedService.listBox().then(
                res => {
                    const groups = res.data;
                    if(ID_GROUPS.DOT === BOXGROUP_ID) groups.push(BOX_DOT2)
                    this.setState({
                        groups: groups,
                    });
                }
            ).catch(err => console.log(err))
        }
    
        if(!authService.getUserInfo()) {
            authService.getMe().then(res => {
                const user = res.data?.user
                authService.setUserInfo(user)
                this.setState({
                    userName: user.full_name
                })
            })
        }

    }

    logOut() {
        authService.setToken({ id: "" });
        authService.setGroupId("");
        window.localStorage.clear();
        window.sessionStorage.clear();
        goTo("app/login");
    }
    handleDrawerToggle() {
        this.setState({
            mobileOpen: !this.state.mobileOpen
        });
    };
    render() {
        const { groups, time, group_id, auth, mobileOpen, boxSelected, exportExcel, metricSelected, searchRecords, userName } = this.state;
        const { domain } = this;
        return (
            <div className="App">
                {auth ?
                    // window.localStorage.getItem('group_id') === 'KKKXW2TW' ?
                    //     <Alert 
                    //         variant="filled" 
                    //         severity="error"
                    //         action={
                    //             <Button color="inherit" size="small" onClick={() => this.logOut()}>
                    //               Đăng xuất
                    //             </Button>
                    //         }
                    //     >
                    //         Tài khoản của bạn đã bị vô hiệu hoá, vui lòng liên hệ với nhân sự chuyên trách thuộc Thuận Phong để được hỗ trợ!</Alert>
                    // :
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <AppBar
                            position="fixed"
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                            }}
                            style={{ backgroundColor: "white", boxShadow: "none", border: '1.5px solid #0000001f' }}
                        >
                            <Toolbar>
                                <Grid container className='align-center'>
                                    <Grid item sx={{ display: { xs: 1, sm: 'none' } }}>
                                        <IconButton
                                            color="success"
                                            edge="start"
                                            onClick={() => this.handleDrawerToggle()}
                                            sx={{ mr: 2, display: { sm: 'none' } }}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={8} md={10}
                                    // className="menu-app"
                                    >
                                        {[`${DOMAIN.MONITOR}/list`, DOMAIN.CHART].includes(domain) ?
                                            groups.length !== +0 &&
                                            <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
                                                <MenuApp
                                                    searchRecords={searchRecords}
                                                    handleSearchRecords={this.handleSearchRecords}
                                                    groups={groups}
                                                    time={time}
                                                    groupId={group_id}
                                                    domain={domain}
                                                    parentCallBack={this.handleChangeBoxGroup}
                                                    boxSelected={boxSelected}
                                                    metricSelected={metricSelected}
                                                    handleChangeBoxSelected={this.handleChangeBoxSelected}
                                                    handleChangeMetricSelected={this.handleChangeMetricSelected}
                                                    handleExportExcel={this.handleExportExcel}
                                                />
                                            </Grid>
                                            :
                                            <>
                                                <Grid container
                                                    sx={{ display: { md: 'none' }, alignItems: 'center' }}
                                                    className="logo_responsive"
                                                >
                                                    <Grid xs={4} item>
                                                        <img src={logo} alt="logo" />
                                                    </Grid>
                                                    <Grid xs={8} item>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize: 14,
                                                                color: 'red',
                                                            }}
                                                        >THUẬN PHONG</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container
                                                    sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, alignItems: 'center' }}
                                                    className="logo_responsive"
                                                >
                                                    <Grid md={8} item>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize: 14,
                                                                color: '#4385f4',
                                                            }}
                                                        ><PersonIcon /> {userName}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>

                                    <Grid item xs={2} md={2} lg={2} className="end">
                                        <Tooltip title="Tải lại trang">
                                            <IconButton
                                                color="success"
                                                onClick={() => window.location.reload()}
                                            >
                                                <RefreshIcon className="fontsz-30 mr-10" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Thoát">
                                            <IconButton
                                                color="success"
                                                onClick={this.logOut}
                                            >
                                                <LogoutIcon className="fontsz-30" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <Box
                            component="nav"
                            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                        >
                            <Drawer
                                container={this.container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={() => this.handleDrawerToggle()}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                                PaperProps={{
                                    sx: {
                                        backgroundColor: "#4285F4",
                                        color: "white",
                                    }
                                }}
                            >
                                {this.drawer(this.domain)}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                                PaperProps={{
                                    sx: {
                                        backgroundColor: "#4285F4",
                                        color: "white",
                                    }
                                }}
                                open
                            >
                                {this.drawer(this.domain)}
                            </Drawer>
                        </Box>
                        <Box
                            component="main"
                            sx={{ mt: { xs: 4.85, md: 8.4 }, width: { xs: '100%', sm: `calc(100% - ${drawerWidth - 12}px)` } }}
                        >
                            <div className="container" >
                                <Switch>
                                    <Route exact path="/" render={() => <Redirect to="/app/dashboard" ></Redirect>} />
                                    <Route exact path="/app" render={() => <Redirect to="/app/dashboard" ></Redirect>} />
                                    <Route path="/app/dashboard" render={() => <DashBoard />} />
                                    <Route path="/app/monitor" render={() => <Monitor handleSearchRecords={this.handleSearchRecords} searchRecords={searchRecords} exportExcel={exportExcel} handleExportExcel={this.handleExportExcel} groups={groups} groupId={group_id} time={time} boxSelected={boxSelected} />} />
                                    <Route path="/app/group" render={() => <Group />} />
                                    <Route path="/app/zone" render={() => <Zone />} />
                                    <Route path="/app/help/audio" render={() => <Audio />} />
                                    <Route path="/app/help" render={() => <Help />} />
                                    <Route path="/app/user" render={() => <User />} />
                                    <Route path="/app/chart" render={() => <ChartComponent time={time} boxSelected={boxSelected} metricSelected={metricSelected} boxs={groups} groupId={group_id} />} />
                                </Switch>
                            </div>
                        </Box>
                    </Box>
                    : <Login />}
            </div>
        );
    }
}

export default withRouter(App);