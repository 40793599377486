import { useState, useEffect, Children } from "react";
import { Grid } from "@mui/material";
import Statistical from "./Statistical/Statistical";
import sharedService from "../../../../Services/SharedService";
import Map from "./Map/Map";
import { BuildChartData, adjustRAData, METRIC_GROUPS } from "../../../Components/Chart/Component/chart_data";
import LineChart from "../../../Components/Chart/Component/LineChart";
import BarChart from "../../../Components/Chart/Component/BarChart";
import { GetUnit } from "../../../../Utils/helper";
import MultiLineChart from "../../../Components/Chart/Component/MultiLineChart";
import WaterLevelView from "../../../Components/Chart/Component/WaterLevelView";

const DashBoardAC = ({ group, center, isLoading }) => {
    const [charts, setCharts] = useState();
    const [recordsRA, setRecordsRA] = useState([]);
    const [dataWaterLevelView, setDataWaterLevelView] = useState()

    useEffect(async () => {
        const groupRecord = await (await sharedService.listRecordBoxGroup()).data;
        const recordWAU = groupRecord.find(g => g.box.type === 'WA').records
        const WAU = recordWAU.length > 0 ? recordWAU[0].WAU : 0;
        groupRecord.map(g => {
            if(g.box.type === 'DR') {
                g.metrics.push("WAU")
                g.box.metrics.push({name: "Mực nước thượng lưu", code: "WAU", metric: "WA"})
                g.records.map(r => {
                    r.WAU = WAU
                    return r
                })
            }
            return g
        })
        setRecordsRA(groupRecord.filter(g => g.box.type === 'RA'))
        const result = groupRecord.map(g => {
            const chart = BuildChartData(g.records, g.box, WAU)
            return chart
        })
        const docs = adjustRAData(result.filter(r => r))
        docs.sort((a, b) => {
            if (a.metric === "WAU") return -1;
            if (b.metric === "WAU") return 1;
            return 0;
        });
        docs.map(d => {
            if(d.metric === "DR" && !d.datasets.some(s => s.code === "DR2")) {
                d.datasets.map(da => {
                    if(da.code === "QS") da.label = "Lưu lượng qua cống"
                })
            }
        })
        setCharts(docs)
    }, [])

    return (
        <>
            <Grid className="DashBoardAC" container columns={12}>
                <Grid item xs={12} lg={6} sx={{ marginTop: { xs: '20px', lg: '0px' } }} className="list-chart">
                    {charts && Children.toArray(charts.map(t => {
                        if (t.metric === "WAU" && t.box_id === "LPDNWOUM") return <div className="water-section"><WaterLevelView box_id={t.box_id} data={dataWaterLevelView} /></div>
                        else if (t.type === 'line' && t.metric !== 'WA') return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                            <div className="chart-item">
                                <LineChart height='100%' min={400} data={t} max={500} />
                            </div>
                        </div>
                        else if (t.type === 'bar') return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {METRIC_GROUPS.find(m => m.metric === t.metric).label} {GetUnit(t.metric)}</p>
                            <div className="chart-item">
                                <BarChart height='100%' data={t} min={0} max={10} />
                            </div>
                        </div>
                        else if (t.type === "multiLine") return <div className="chart-container">
                            <p>Biểu đồ dữ liệu {t.box_id === "RIENVHK4" ? "đo mở tràn" : METRIC_GROUPS.find(m => m.metric === t.metric).label}  </p>
                            <div className="chart-item">
                                <MultiLineChart height='100%' data={t} min={0} max={10} />
                            </div>
                        </div>
                    }))}
                </Grid>
                <Grid item xs={0} lg={0.2}>
                </Grid>
                <Grid item xs={12} lg={5.5} sx={{ maxWidth: '1000px', minWidth: '260px', width: '200px', marginTop: { xs: '20px', lg: '0px' } }}>
                    <Statistical data={recordsRA} />
                    <Map group={group} center={center} isLoading={isLoading} />
                </Grid>
            </Grid>
        </>
    )
}

export default DashBoardAC;