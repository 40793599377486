import { useEffect, useState } from "react";
import { ID_GROUPS } from "../../../../../Utils/const";
import authService from "../../../../../Services/AuthService";
import { goTo } from "../../../../../Utils/helper";
import ModalConfirm from "../../../../Components/ModalConfirm/ModalConfirm"
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const AudioApiUrl = "https://tp-amchua.centralbox.net/api/"

const AudioUser = {
    "username": "operator",
    "password": "123456aA@"
}

export default function Audio() {
    const [tokenAudio, setTokenAudio] = useState("")
    const [settings, setSettings] = useState({})
    const group_id = localStorage.getItem("group_id")
    const [isAudioOn, setIsAudioOn] = useState(false) 
    const [modal, setModal] = useState({
        title: "Thông báo",
        message: "",
        open: false
    })

    function delayTime(s) {
        return new Promise(resolve => setTimeout(resolve, s * 1000));
    }

    async function SettingAudio(settings) {
        const { long, times, delay } = settings
        const arr = new Array(times).fill(1)

        await Promise.all(arr.map(async (a, i) => {
            await delayTime((delay + long) * i)
            ToggleAudio("on")
            setIsAudioOn(true)
            await delayTime(long)
            ToggleAudio("off")
            setIsAudioOn(false)
        }))
    }

    const loginAudio = async () => {
        const rawResponse = await fetch(AudioApiUrl + "auth/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(AudioUser)
        });
        const content = await rawResponse.json();
        const result = content.data.accessToken
        setTokenAudio(result)
    }

    const ToggleAudio = async (type) => {
        let check = false
        if(type === "on") check = true
        await fetch(AudioApiUrl + "loras/relay", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenAudio}`
            },
            body: JSON.stringify({
                "node": 1,
                "pin": 6,
                "on": check
            })
        });
    }

    const logOut = () => {
        authService.setToken({ id: "" });
        authService.setGroupId("");
        sessionStorage.setItem('check_login_ac', false);
        goTo("app/login");
    }

    useEffect(() => {
        if (group_id !== ID_GROUPS.AC) {
            authService.setToken({ id: "" });
            authService.setGroupId("");
            goTo("app/login");
        } else {
            let checkStorage = sessionStorage.getItem('check_login_ac')
            if (checkStorage === null) {
                logOut()
            }
            else if (checkStorage !== null && !checkStorage) {
                logOut()
            } else {
                loginAudio()
            }
        }
    }, [group_id])

    const LevelAudio = [
        {
            button: "Cảnh báo xả lũ",
            title: "Cảnh báo trước khi xả lũ: 30 phút trước khi xả lũ, 2 hồi còi dài 20s, cách nhau 10s",
            delay: 10,
            long: 20,
            times: 2
        },
        {
            button: "Xả lũ cấp báo động 1",
            title: "Hiệu lệnh xả lũ cấp báo động 1: 3 hồi còi, mỗi hồi dài 20 giây, cách nhau 10 giây",
            delay: 10,
            long: 20,
            times: 3
        },
        {
            button: "Xả lũ cấp báo động 2",
            title: "Hiệu lệnh xả lũ cấp báo động 2: 4 hồi còi, mỗi hồi dài 20 giây, cách nhau 10 giây",
            delay: 10,
            long: 20,
            times: 4
        },
        {
            button: "Xả lũ cấp báo động 3",
            title: "Hiệu lệnh xả lũ cấp báo động 3: 5 hồi còi, mỗi hồi dài 20 giây, cách nhau 10 giây",
            delay: 10,
            long: 20,
            times: 5
        },
        {
            button: "Kết thúc xả lũ",
            title: "Kết thúc xả lũ: 1 hồi còi 60 giây, cách nhau 10 giây",
            delay: 10,
            long: 60,
            times: 1
        },
    ]



    const answer = (isTrue) => {
        if (isTrue) {
            SettingAudio(settings)
            setModal({ ...modal, open: false })
        } else {
            setModal({ ...modal, open: false })
        }
    }

    function handleClick(index) {
        const { long, times, delay } = LevelAudio[index]
        setSettings({ ...settings, long, times, delay })
        setModal({ ...modal, open: true, message: LevelAudio[index].title })
    }

    return <div className="HelpAudio">
        <div className="audio">Loa Cảnh Báo: {isAudioOn ? "Bật" : "Tắt"} {isAudioOn ? <VolumeUpIcon sx={{fontSize: 40}}/> :<VolumeOffIcon sx={{fontSize: 40}}/>} </div>
        <ModalConfirm modal={modal} functionCallBack={answer} />
        {LevelAudio.map((l, i) => {
            return <div key={i} className="item">
                <div className="item-btn">
                    <button onClick={() => handleClick(i)}>{l.button}</button>
                </div>
                <div className="title">{l.title}</div>
            </div>
        })}

    </div>
}