import { ID_GROUPS, LOCAL_STORAGE } from "../../../Utils/const";

const round = (value) => Math.round((value) * 100) / 100;

const getGroupId = () => {
    const id = localStorage.getItem(LOCAL_STORAGE.GROUP_ID)
    if (!id) return ""
    return id
}

const Interpolation = [
    {
        group_id: 'KKKXW2TW',
        image: 'songluy_bieudo.png',
        value: [
            [124.46, , 128.22],
            [122.43, 127.27],
            [119, 124.57],
            [118, 118],
            [118, 118],
            [120.09, 121.66],
            [122.05, 126.59],
            [125.08, 128.07],
            [128.55, 129.5],
            [129, 129.5],
            [128.85, 129.5],
            [127.32, 129.5]
        ],
        areaD: 130.2,
        areaE: 131.2
    },
    {
        group_id: 'WFYOUGL1',
        image: 'duongdong_bieudo.png',
        value: [
            [26.7],
            [24],
            [21.3],
            [18.1],
            [14.5],
            [14.5, 18.4],
            [21.8, 28.6],
            [25.5, 28.6],
            [28.4, 28.6],
            [28.6],
            [28.6],
            [28.6]
        ],
        areaD: 28.77,
        areaE: 29.93
    },
    {
        group_id: 'IXC8QY8W',
        image: 'caymen_bieudo.png',
        value: [
            [18.69, 19.18],
            [17.31, 17.85],
            [15.92, 16.51],
            [14.41, 14.81],
            [12.5, 12.5],
            [12.5, 14.98],
            [13.94, 17.29],
            [17.15, 19.85],
            [20.72, 21.1],
            [21.1, 21.1],
            [21.1, 21.1],
            [19.97, 20.41]
        ],
        areaD: 21.1,
        areaE: 21.89
    },
    {
        group_id: 'EHFJZIUT',
        image: 'khegiua_bieudo.png',
        value: [
            [41.88, 44.43],
            [39.65, 42.26],
            [36.82, 40.9],
            [32.76, 38.7],
            [32.5, 32.5],
            [32.5, 32.5],
            [33.49, 47],
            [47, 47],
            [46.84, 47],
            [46.57, 47],
            [45.75, 46.95],
            [44.31, 46.29]
        ],
        areaD: 47,
        areaE: 49.81
    },
    {
        group_id: '52ZVBWMR',
        image: 'amchua_bieudo.png',
        value: [
            [34.5, 35.3],
            [34, 35.3],
            [32.82, 34.8],
            [32.2, 34.3],
            [32.2, 34.2],
            [29.5, 32.7],
            [28.9, 32.2],
            [27.1, 31.2],
            [23.9, 29],
            [23.9, 29],
            [25.4, 30.8],
            [28.05, 33.8]
        ],
        areaD: 35.5,
        areaE: 36
    }
];

//// Tính toán khu vực điều phối hồ
export function CalculateCoordination(z_value) {
    z_value = round(z_value)
    const d = new Date();
    const month = d.getMonth();
    const group_id = getGroupId()
    const currentInterpol = Interpolation.find(l => l.group_id === group_id);

    if (!currentInterpol) return 'A'

    const { areaD, areaE } = currentInterpol;

    if (group_id === 'KKKXW2TW') {
        const [areaB, areaC] = currentInterpol.value[month];
        if (z_value > areaE) return 'E';
        else if (z_value > areaD) return 'D';
        else if (z_value > areaC) return 'C';
        else if (z_value > areaB) return 'B';
        else return 'A'
    } else if (group_id === 'WFYOUGL1') {
        const [areaB, areaA] = currentInterpol.value[month];
        if (z_value > areaE) return 'E';
        else if (z_value > areaD) return 'D';
        else if (areaA && z_value > areaA) return 'A';
        else if (z_value > areaB) return 'B';
        else return 'C'
    } else if (group_id === 'IXC8QY8W') {
        const [areaB, areaA] = currentInterpol.value[month];
        if (z_value > areaE) return 'E';
        else if (z_value > areaD) return 'D';
        else if (z_value > areaA) return 'A';
        else if (z_value > areaB) return 'B';
        else return 'C'
    } else if (group_id === ID_GROUPS.KG) {
        if (!z_value) return '';
        const [areaB, areaC] = currentInterpol.value[month];
        if (z_value > areaE) return 'E';
        else if (z_value > areaD) return 'D';
        else if (z_value > areaC) return 'C';
        else if (z_value > areaB) return 'B';
        else return 'A';
    } else if (group_id === ID_GROUPS.AC) {
        if (!z_value) return '';
        const [areaB, areaC] = currentInterpol.value[month];
        if (z_value > areaE && [9, 10, 11, 12, 1]. includes(month)) return 'E';
        else if (z_value > areaD && [9, 10, 11, 12, 1]. includes(month)) return 'D';
        else if (z_value > areaC) return 'C';
        else if (z_value > areaB) return 'B';
        else return 'A';
    }
}

/// Lấy url image
export function getUrlImage() {
    const currentInterpol = Interpolation.find(l => l.group_id === getGroupId());
    if (currentInterpol) {
        const image = currentInterpol?.image;
        return image
    }
    return 'loading.jpg';
}