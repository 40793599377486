
import { useEffect, useRef } from "react";
import sharedService from "../../../../Services/SharedService";
import { RECTS_DEFAULT } from "./water_level_data";
import { CalculateVolume } from "../../Interpolation/volume";
import { ID_GROUPS } from "../../../../Utils/const";
const WaterLevelView = (props) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const group_id = localStorage.getItem("group_id");

        if (props.box_id) {
            const ONE_HOUR = 3600e3;
            const filter = {
                min_ts: Date.now() - 24 * ONE_HOUR,
                limit: 1,
            };
            sharedService.listRecordByBox(props.box_id, filter).then((res) => {
                let record = res.data.records[0] || [];
                if (!props.box_id) return;
                const rect_defaut = RECTS_DEFAULT.find((r) => r.box_id === props.box_id);
                if (!rect_defaut) return;
                const rect = JSON.parse(JSON.stringify(rect_defaut));
                if (!record.WAU || record.WAU <= rect.value.default) {
                    console.log('yếu');
                } else {
                    const adjust = +(record.WAU - rect.value.default) * rect.ratio;
                    rect.value.y -= adjust;
                }
                const canvas = canvasRef.current
                if (!canvas) return;
                const ctx = canvas.getContext("2d");
                const base_dir = "../images/water_proof2";
                const image = new Image();
                image.onload = () => {
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0);

                    // ctx.fillStyle = "#FF0000"
                    // ctx.fillRect(65, 555.5, 3, 2);

                    if(props.box_id === 'WK34ZRT9') {
                        ctx.font = "26px Roboto, Helvetica, Arial, sans-serif ";
                        ctx.fillStyle = "#4285F4";
                        ctx.fillText("Qx tổng (m3/s):", 600, 180);
                        ctx.fillText("Qx tràn (m3/s):", 600, 240);
                        ctx.fillText("Qx cống (m3/s):", 600, 300);
                        ctx.fillText("V/Vtk (%):", 600, 360);

                        ctx.font = "55px Roboto, Helvetica, Arial, sans-serif";
                        ctx.fillStyle = "#EA4335";
                        const total_q = Math.round((props?.data?.q_of + props?.data?.q) * 100) / 100
                        ctx.fillText(total_q, 805, 185);
                        ctx.fillText(props?.data?.q, 805, 245);
                        ctx.fillText(props?.data?.q_of, 805, 305);

                        const v_radio = Math.round(CalculateVolume(record.WAU, group_id) / rect_defaut.v_design * 10000) / 100
                        ctx.fillText(v_radio, 805, 365)
                    }else {
                        ctx.font = "26px Roboto, Helvetica, Arial, sans-serif";
                        ctx.fillStyle = "#4285F4";
                        ctx.fillText("V/Vtk (%):", 650, 240);
                        ctx.font = "55px Roboto, Helvetica, Arial, sans-serif";
                        ctx.fillStyle = "#EA4335";
                        const v_radio = Math.round(CalculateVolume(record.WAU, group_id) / rect_defaut.v_design * 10000) / 100
                        ctx.fillText(v_radio, 780, 240)
                    }
                    ctx.font = "bold 32px Roboto, Helvetica, Arial, sans-serif";
                    if(group_id === ID_GROUPS.AC) {
                        ctx.fillText(rect.value.title1 + record.WAU, 200, rect.value.y - 10);
                        ctx.fillText(rect.value.title2 + CalculateVolume(record.WAU, group_id), 170, rect.value.y2);
                    }else {
                        ctx.fillText(rect.value.title1 + record.WAU, 90, rect.value.y - 10);
                        ctx.fillText(rect.value.title2 + CalculateVolume(record.WAU, group_id), 20, rect.value.y2);
                    }
                    
                    const grd = ctx.createLinearGradient(1, 0, 0, 0);
                    grd.addColorStop(0, "#40b5e7");
                    grd.addColorStop(1, "#40b5e7");
                    
                    // Fill with gradient
                    ctx.fillStyle = grd;
                    const adjustGradient = rect_defaut.value.bottom - rect.value.y
                    if(record.WAU >= rect.value.default) {
                        ctx.fillRect(rect_defaut?.value?.left || 73, rect.value.y, rect_defaut.value.right - rect_defaut.value.left, adjustGradient);
                        ctx.stroke();
                    }
                    if(group_id === ID_GROUPS.AC) {
                        const adjust = +(record.WAU - rect.value.default) * rect.ratio;
                        const arr = new Array(Math.round(adjust)).fill(1)
                        arr.map((a, i) => {
                            const bottom = rect_defaut.value.bottom;
                            const right = rect_defaut.value.right;
                            ctx.moveTo(right, bottom - i);
                            ctx.lineTo(right + 3.75 * i, bottom - i);
                            ctx.strokeStyle = '#40b5e7'
                            ctx.lineWidth = 2;
                        })
                        ctx.stroke();
                    }else {
                        rect_defaut?.fill.map((x, i) => {
                            if(record.WAU <= rect.value.default && x < 235) return
                            const bottom = rect_defaut.value.bottom + 1;
                            const right = rect_defaut.value.right;
                            ctx.moveTo(x, bottom + i);
                            ctx.lineTo(right, bottom + i);
                            ctx.strokeStyle = '#40b5e7'
                            ctx.lineWidth = 2;
                        })
                        ctx.stroke();
                    }
                };
                image.src = `${base_dir}/${rect.image}`;
            });
        }
    }, [props.box_id])

    return (
        <div>
            <canvas ref={canvasRef} id="myCanvas-Water" />
        </div>
    )
}

export default WaterLevelView;